.App {
  text-align: center;
  background-color: #2f0404;
  color: bisque;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.image{
  height:40%;
  width: 40%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.main-footer .footer-bottom {
  position: relative;
  text-align: center;
  padding: 20px 0px;
  font-size: 15px;
  background-color: #2f0404;
  color: bisque;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-color {
  background-color: #2f0404;
}

a#collasible-nav-dropdown.dropdown-toggle.nav-link::after {
  color: wheat;
}

.navbar .navbar-toggler .icon-bar {
  background: white !important;
}

.main-footer .footer-bottom a {
  color: wheat;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
